<template>
  <section class="payment">
    <div class="payment-cards" v-if="!isNext">
      <inline-svg :src="require('../../assets/icon/svg/credit-cards.svg')" />
      <p><small>限用台灣核發信用卡：Master / VISA / JCB</small></p>
    </div>
    <form @change="validatePayment" v-if="!isNext">
      <fieldset class="credit-card">
        <ul class="credit-cards">
          <li class="credit-cards-item card-number">
            <label for="card-number">信用卡卡號</label>
            <input
              id="card-number"
              autofocus
              maxlength="22"
              required
              type="text"
              autocomplete="cc-number"
              autocorrect="off"
              autocapitalize="none"
              placeholder="**** **** **** ****"
              class="input is-static-line"
              inputmode="numeric"
              v-cardformat:formatCardNumber
              ref="cardNumInput"
              :data-error="cardErrors.cardNumber ? true : false"
              v-model="cardNumber"
              :class="[
                cardNumber.length < 19
                  ? ''
                  : cardErrors.cardNumber !== ''
                  ? 'invalid'
                  : 'valid',
              ]"
            />
            <div v-if="cardErrors.cardNumber !== ''" class="error">
              <small>{{ cardErrors.cardNumber }}</small>
            </div>
          </li>
          <li class="credit-cards-item field card-expiration">
            <label for="card-expiration" class="field-label"
              >信用卡到期日</label
            >
            <input
              ref="cardExpInput"
              id="card-expiration"
              :data-error="cardErrors.cardExpiry ? true : false"
              v-model="cardExpiry"
              autocomplete="cc-exp"
              maxlength="5"
              required
              type="text"
              placeholder="MM/YY"
              class="input is-static-line"
              inputmode="numeric"
              v-cardformat:formatCardExpiry
              :class="[
                cardExpiry.length < 7
                  ? ''
                  : cardErrors.cardExpiry !== ''
                  ? 'invalid'
                  : 'valid',
              ]"
            />
            <div v-if="cardErrors.cardExpiry !== ''" class="error">
              <small>{{ cardErrors.cardExpiry }}</small>
            </div>
          </li>
          <li class="credit-cards-item field card-cvc">
            <label for="card-cvc" class="field-label">信用卡驗證碼</label>
            <input
              maxlength="4"
              required
              id="card-cvc"
              type="text"
              placeholder="CVC"
              class="input is-static-line"
              pattern="[0-9]*"
              inputmode="numeric"
              v-cardformat:formatCardCVC
              ref="cardCvcInput"
              :data-error="cardErrors.cardCvc ? true : false"
              v-model="cardCvc"
              autocomplete="cc-csc"
              :class="[
                cardCvc.length === 0
                  ? ''
                  : cardErrors.cardCvc !== ''
                  ? 'invalid'
                  : 'valid',
              ]"
            />
            <div v-if="cardErrors.cardCvc !== ''" class="error">
              <small>{{ cardErrors.cardCvc }}</small>
            </div>
            <small v-if="cardCvc.length === 0 && cardErrors.cardCvc === ''"
              >請輸入卡片背面末 3 碼</small
            >
          </li>
        </ul>
      </fieldset>
      <fieldset class="invoice">
        <legend class="invoice-legend">發票資料</legend>
        <ul class="invoice-types">
          <li class="invoice-type e-invoice-list">
            <input
              id="e-invoice"
              name="e-invoice"
              type="radio"
              value="1"
              class="invoice-types-input"
              v-model="invoiceTypeSelected"
            />
            <label for="e-invoice" class="label">電子發票</label>
          </li>
          <li>
            <ul class="e-invoice" v-if="invoiceTypeSelected === '1'">
              <li>
                <input
                  type="checkbox"
                  class="invoice-type-checkbox"
                  v-model="toggleTaxId"
                  id="taxId"
                /><label for="taxId">統一編號</label>
              </li>
              <ul class="tax-id" v-if="toggleTaxId">
                <li class="tax-id-form">
                  <div class="tax-id-input">
                    <input
                      name="tex-id"
                      type="text"
                      class="input is-static-line"
                      placeholder="請輸入統一編號"
                      v-model="taxId"
                      maxlength="8"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      v-cardformat:restrictNumeric
                      :class="[
                        taxId.length === 0
                          ? ''
                          : taxIdError
                          ? 'invalid'
                          : 'valid',
                      ]"
                    />
                  </div>
                  <div v-if="taxIdError" class="error">
                    <small>{{
                      isRequired ? "請輸入統一編號" : "請輸入正確的統一編號格式"
                    }}</small>
                  </div>
                </li>
                <li class="tax-id-form">
                  <div class="tax-id-input">
                    <input
                      name="invoice-title"
                      type="text"
                      v-model="title"
                      class="input is-static-line"
                      placeholder="請輸入發票抬頭"
                      maxlength="50"
                      :class="[
                        title.length == 0
                          ? ''
                          : titleError
                          ? 'invalid'
                          : 'valid',
                      ]"
                    />
                  </div>
                  <div v-if="titleError" class="error">
                    <small>請輸入發票抬頭</small>
                  </div>
                </li>
                <li class="tax-id-form">
                  <div class="tax-id-input">
                    <input
                      name="invoice-address"
                      type="text"
                      v-model="address"
                      class="input is-static-line"
                      placeholder="請輸入收件地址"
                      maxlength="50"
                      autocomplete="street-address"
                      :class="[
                        address.length === 0
                          ? ''
                          : addressError
                          ? 'invalid'
                          : 'valid',
                      ]"
                    />
                  </div>
                  <div v-if="addressError" class="error">
                    <small>請輸入收件地址</small>
                  </div>
                </li>
              </ul>
            </ul>
          </li>

          <li class="invoice-type">
            <input
              id="barcode"
              name="barcode"
              type="radio"
              value="2"
              v-model="invoiceTypeSelected"
              class="invoice-types-input"
            />
            <label for="barcode">手機條碼</label>
          </li>
          <li>
            <ul class="barcode" v-if="invoiceTypeSelected === '2'">
              <li class="barcode-form">
                <div class="barcode-input">
                  <input
                    name="barcode-code"
                    type="text"
                    v-model="barcode"
                    class="input is-static-line"
                    placeholder="請輸入手機條碼"
                    :class="[
                      barcode.length === 0
                        ? ''
                        : barcodeError
                        ? 'invalid'
                        : 'valid',
                    ]"
                  />
                </div>
                <div v-if="taxIdError" class="error">
                  <small>{{
                    isRequired ? "請輸入手機條碼" : "請輸入正確的手機條碼格式"
                  }}</small>
                </div>
              </li>
            </ul>
          </li>
          <li class="invoice-type">
            <input
              id="donation"
              name="donation"
              type="radio"
              value="4"
              v-model="invoiceTypeSelected"
              class="invoice-types-input"
            />
            <label for="donation">發票捐贈</label>
          </li>
          <li class="invoice-type donations" v-if="invoiceTypeSelected === '4'">
            <ul v-for="donation in donations" :key="donation.id">
              <li>
                <label :for="donation.id" class="radios">
                  <input
                    type="radio"
                    v-model="donationSelected"
                    :value="donation.id"
                    :id="donation.id"
                    class="invoice-types-input_narrow"
                  />
                  <span class="radio-checkmark-box">
                    <span class="radio-checkmark"></span>
                  </span>
                  {{ donation.name }}
                </label>
              </li>
            </ul>
          </li>
        </ul>
      </fieldset>
      <div class="next">
        <button
          type="button"
          class="button is-rounded is-fullwidth is-link"
          :disabled="isDisabled"
          @click="handleNext()"
        >
          下一步
        </button>
      </div>
    </form>
    <form
      action=""
      v-if="isNext"
      @change="validateDeliver"
      @submit.prevent="handleSubmit()"
    >
      <fieldset class="deliver">
        <legend class="deliver-legend">充電資料</legend>
        <ul>
          <li class="phone">
            <label for="phone">手機號碼</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              class="input is-static-line"
              pattern="[0-9]*"
              inputmode="numeric"
              required
              maxlength="10"
              placeholder="請輸入您的手機號碼"
              v-cardformat:restrictNumeric
              v-model="phone"
              :class="[
                phone.length === 0 ? '' : phoneError ? 'invalid' : 'valid',
              ]"
            />
            <div
              v-if="phoneError"
              class="error"
              :class="[phoneError ? 'error' : 'deliver-message']"
            >
              <!-- <small>
                {{
                  phoneError
                    ? '請輸入正確的手機號碼'
                    : '系統將發送充電明細簡訊到您的手機'
                }}</small
              > -->
              <small> {{ phoneError ? "請輸入正確的手機號碼" : "" }}</small>
            </div>
          </li>
          <li class="email">
            <label for="email">Email</label>
            <input
              autofocus
              id="email"
              name="email"
              type="email"
              v-model="email"
              class="input is-static-line"
              placeholder="請輸入您的電子郵件信箱"
              ref="emailInput"
              :class="[
                email.length === 0 ? '' : emailError ? 'invalid' : 'valid',
              ]"
            />
            <div class="deliver-message">
              <small v-if="!emailError"
                >請確認資訊無誤避免發票無法開立，此資料僅供開立發票使用</small
              >
            </div>
            <div v-if="emailError && !isRequired" class="error">
              <small>請輸入正確的電子信箱格式</small>
            </div>
            <div v-if="emailError && isRequired" class="error">
              <small>請輸入電子信箱</small>
            </div>
          </li>
          <li class="check">
            <input
              id="check"
              type="checkbox"
              class="invoice-type-checkbox"
              v-model="check"
              :class="{ invalid: isRequired }"
            /><label for="check" :class="{ invalid: isRequired }"
              >我已閱讀並同意
              <router-link
                target="_blank"
                :to="{ name: 'Privacy' }"
                class="router-link"
              >
                U-POWER 隱私權政策</router-link
              >
              與
              <router-link
                target="_blank"
                :to="{ name: 'Service' }"
                class="router-link"
              >
                服務條款</router-link
              ></label
            >
          </li>
          <div class="submit">
            <button
              type="submit"
              class="button is-rounded is-fullwidth is-link"
              :disabled="isProcessing || isSubmitDisabled"
            >
              {{ isProcessing ? "送出中..." : "確認送出，預備開始充電" }}
            </button>
          </div>
          <div class="description">
            <!-- <small>
              免責聲明：本服務將以簡訊方式傳送充電進度；或以 Email
              提供電子發票收據，請詳加確認您所填寫的資料，
              若因填寫錯誤或遺失恕不再補發。
            </small> -->
            <small>
              免責聲明：本服務將以 Email
              提供電子發票收據，請詳加確認您所填寫的資料，
              若因填寫錯誤或遺失恕不再補發。
            </small>
          </div>
        </ul>
      </fieldset>
    </form>
  </section>
</template>

<script>
import {
  isGuiNumberValid,
  isEInvoiceCellPhoneBarcodeValid,
} from "taiwan-id-validator";
import chargingAPI from "../../apis/charging";

export default {
  name: "CreditCard.vue",
  data() {
    return {
      cardNumber: "",
      cardExpiry: "",
      cardCvc: "",
      email: "",
      taxId: "",
      title: "",
      address: "",
      barcode: "",
      phone: "",
      invoiceTypeSelected: "1",
      donationSelected: "5224",
      toggleTaxId: false,
      check: false,
      cardErrors: {
        cardNumber: "",
        cardExpiry: "",
        cardCvc: "",
      },
      emailError: false,
      taxIdError: false,
      titleError: false,
      addressError: false,
      barcodeError: false,
      phoneError: false,

      isRequired: false,
      isProcessing: false,

      isDisabled: true,
      isNext: false,
      isSubmitDisabled: true,

      donations: [
        { id: "5224", name: "社團法人台灣綠能公益發展協會" },
        { id: "7676", name: "社團法人台灣青年氣候聯盟" },
        { id: "8999", name: "社團法人中華民國荒野保護協會" },
        { id: "2321", name: "財團法人環境品質文教基金會" },
        { id: "7505", name: "中華社會福利聯合勸募協會" },
      ],
    };
  },
  methods: {
    handleNext() {
      this.isNext = true;
      this.scrollToTop();
    },
    handleSubmit() {
      this.isProcessing = true;
      const taxId = this.toggleTaxId === true;
      const barcode = this.invoiceTypeSelected === '2';
      const donation = this.invoiceTypeSelected === '4';
      let phone = this.phone;
      let formattedPhone = `+886${phone.slice(1)}`;
      let code
      if (taxId) {
        code = `${this.taxId}`;
      }
      if (barcode) {
        code = `${this.barcode}`;
      }
      if (donation) {
        code = `${this.donationSelected}`;
      }
      let expiry = this.cardExpiry;
      expiry = expiry.replace("/", "");
      let cardexpiry = expiry.split(" ").join("");
      let number = this.cardNumber;
      let cardnumber = number.split(" ").join("");
      const data = {
        stationid: this.$route.params.stationId,
        connectorid: this.$route.params.connectorId,
        phonenumber: formattedPhone,
        email: this.email,
        paymentmethod: 4,
        paymenttoken: "",
        cardnumber: cardnumber,
        cardexpiry: cardexpiry,
        cardcvv: this.cardCvc,
        invoicetype: this.toggleTaxId ? 5 : Number(this.invoiceTypeSelected),
        code: code,
        invoicetitle: this.title,
        invoiceAddress: this.address,
        invoiceZip: '',
        recipient: ''
      }
      this.postGuestCharging(data)
      this.isProcessing = false
    },
    async postGuestCharging(guestData) {
      try {
        const { data, status, code } = await chargingAPI.charging.postGuest(
          guestData
        );
        const { connectorId, stationId } = this.$route.params;
        if (!(status >= 200 && status < 300) || code === "08105") {
          this.$router.push({
            name: "ErrorHint",
            params: {
              stationId: stationId,
              connectorId: connectorId,
            },
          });
          throw new Error(status);
        }
        this.$store.dispatch("updateChargingData", data);
        const { paid } = data;
        const paidStatus = {
          success: 1,
          failed: 2,
        };
        if (paid === paidStatus.failed) {
          this.$router
            .replace({
              name: "CardFailed",
              params: { connectorId: connectorId, stationId: stationId },
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (paid === paidStatus.success) {
          this.$router
            .replace({
              name: "CardAuth",
              params: { connectorId: connectorId, stationId: stationId },
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } catch (error) {
        const { connectorId, stationId } = this.$route.params;
        this.$router.replace({
          name: "ErrorHint",
          params: {
            stationId: stationId,
            connectorId: connectorId,
          },
        });
        console.log(error);
      }
    },
    validateDeliver() {
      if (this.check === true) {
        this.isRequired = false;
      }
      if (
        this.email &&
        this.phone &&
        !this.emailError &&
        !this.phoneError &&
        this.check === true
      ) {
        this.isSubmitDisabled = false;
      } else {
        this.isSubmitDisabled = true;
      }
    },
    validatePayment() {
      const eInvoice =
        this.invoiceTypeSelected === '1' && this.toggleTaxId === false;
      const taxId = this.toggleTaxId;
      const barcode = this.invoiceTypeSelected === '2';
      const donation = this.invoiceTypeSelected === '4';
      const { cardNumber, cardExpiry, cardCvc } = this.cardErrors;

      let defaultOptions =
        cardNumber.length > 0 || cardExpiry.length > 0 || cardCvc.length > 0;
      if (
        (!defaultOptions && donation) ||
        (!defaultOptions && barcode && !this.barcodeError && this.barcode) ||
        (!defaultOptions && eInvoice) ||
        (!defaultOptions &&
          taxId &&
          !this.titleError &&
          !this.addressError &&
          !this.taxIdError &&
          this.title &&
          this.address &&
          this.taxId)
      ) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    validPhone(phone) {
      let re = /^09[0-9]{8}$/g;
      return re.test(phone);
    },
    validEmail(email) {
      let re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  watch: {
    cardNumber: function (val) {
      if (val.length >= 19 && this.$cardFormat.validateCardNumber(val)) {
        this.$refs.cardExpInput.focus();
      } else if (
        val.length >= 19 &&
        !this.$cardFormat.validateCardNumber(val)
      ) {
        this.cardErrors.cardNumber = "請輸入有效的信用卡卡號";
      } else {
        this.cardErrors.cardNumber = "";
      }
    },
    cardExpiry: function (val) {
      if (val.length >= 7 && this.$cardFormat.validateCardExpiry(val)) {
        this.$refs.cardCvcInput.focus();
      } else if (val.length >= 7 && !this.$cardFormat.validateCardExpiry(val)) {
        this.cardErrors.cardExpiry = "請輸入有效的信用卡到期日";
      } else {
        this.cardErrors.cardExpiry = "";
      }
    },
    cardCvc: function (val) {
      if (val.length > 0 && !this.$cardFormat.validateCardCVC(val)) {
        this.cardErrors.cardCvc = "請輸入有效的信用卡驗證碼";
      } else {
        this.cardErrors.cardCvc = "";
      }
    },
    taxId: function (val) {
      if (
        this.toggleTaxId === true &&
        !isGuiNumberValid(val) &&
        val.length > 0
      ) {
        this.taxIdError = true;
        this.isRequired = false;
      } else if (this.toggleTaxId === true && val.length === 0) {
        this.taxIdError = true;
        this.isRequired = true;
      } else if (this.toggleTaxId === true && isGuiNumberValid(val)) {
        this.taxIdError = false;
        this.$refs.title.focus();
      }
    },
    title: function (val) {
      if (this.toggleTaxId === true && val.length > 0) {
        this.titleError = false;
      }
    },
    address: function (val) {
      if (this.toggleTaxId === true && val.length > 0) {
        this.titleError = false;
      }
    },
    barcode: function (val) {
      if (
        this.invoiceTypeSelected === "2" &&
        !isEInvoiceCellPhoneBarcodeValid(val) &&
        val.length > 0
      ) {
        this.barcodeError = true;
        this.isRequired = false;
      } else if (
        this.invoiceTypeSelected === "2" &&
        isEInvoiceCellPhoneBarcodeValid(val)
      ) {
        this.barcodeError = false;
        this.isRequired = false;
      }
    },
    email: function (val) {
      if (val.length > 0 && this.validEmail(val)) {
        this.emailError = false;
        this.$refs.checkInput.focus();
      } else if (val.length > 0 && !this.validEmail(val)) {
        this.emailError = true;
      }
    },
    phone: function (val) {
      if (val.length > 0 && this.validPhone(val)) {
        this.phoneError = false;
      } else if (val.length > 0 && !this.validPhone(val)) {
        this.phoneError = true;
      }
    },
  },
  onMounted() {
    this.$refs.cardNumInput.focus();
  },
};
</script>
